@use 'src/styles/functions/index' as *;

.querySpellCorrection {
  .correctionLink {
    cursor: pointer;
    font-weight: 600;
  }
}

@include for-mobile-extended {
  .querySpellCorrection {
    margin-bottom: 5px;
  }
}
