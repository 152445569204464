@use 'src/styles/functions/index' as *;

.svgIcon {
  user-select: none;
  &.ui:not(.stroke) path {
    fill: currentColor;
  }
  &.ui.stroke path {
    stroke: currentColor;
  }

  &.square {
    @include roundedBoxSmall;
    padding: 2px;
    outline: none;
    width: 20px;
    height: 20px;
    &:focus-visible {
      outline: auto;
    }
    &:hover,
    &:focus-within {
      background-color: var(--background-active);
    }
    rect,
    path {
      fill: var(--text-light);
    }

    &:hover {
      rect,
      path {
        fill: var(--text);
      }
    }
  }
}
