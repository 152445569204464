@use 'src/styles/functions/index' as *;

.betaBadge {
  display: inline-block;
  @include roundedBoxSmall;
  background-color: var(--beta-background-color);
  color: var(--beta-primary-color);
  padding: 0 3px;
  font-size: var(--font-size-small);
  margin-top: -2px;
}
