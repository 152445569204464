@use 'src/styles/functions/index' as *;

.userInitials {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--background-purple);
  color: var(--text-contrast);
  text-align: center;
  padding: 4px 0;
  font-size: var(--font-size-medium);
  display: flex;
  justify-content: center;
  align-items: center;
}
