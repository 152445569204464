@use 'src/styles/functions/index' as *;

.uiInfoTip {
  display: inline-flex;
  cursor: help;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin: 0 3px;
  margin-top: -2px;
  @include circle;
  vertical-align: middle;
  @include animate;
  color: var(--text-hint);

  &:hover {
    color: var(--primary);
    background-color: var(--background-primary-light);
  }

  svg {
    margin: 0 !important;
  }
}

.uiInfoTipPopover {
  cursor: default;
  font-size: var(--font-size-medium);
  line-height: 21px;
  pointer-events: none;

  .MuiPaper-root {
    max-width: 340px;
    @include roundedBoxMedium;
    margin-left: 5px;
  }

  .title {
    font-weight: 500;
    padding: 16px;
    border-bottom: 1px solid;
    border-bottom-color: var(--border);
  }

  .content {
    padding: 16px;

    p {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
