@use 'src/styles/functions/index' as *;
.appFilterRow {
  display: flex;
  align-items: center;
  > button {
    flex-grow: 1;
    color: var(--text);
    font-size: var(--font-size-larger);
    background-color: transparent;
    padding: 8px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      margin-left: 4px;
      color: var(--primary-active);
    }

    @include roundedBoxMedium;
    &:hover:not(.disabled) {
      background: var(--background-active);
    }
    &.selected {
      background-color: var(--background-button-highlight);
      color: var(--text);
    }
    &.disabled {
      cursor: inherit !important;
      > .svgIcon.app {
        filter: grayscale(1);
        z-index: -1;
      }
      color: var(--text-light);
    }
    &:last-child {
      margin-right: 32px;
    }
    > .svgIcon.app {
      margin-right: 8px;
    }
    > .count {
      margin-left: auto;
    }
    .indexingProgress {
      display: inline-flex;
      margin-left: 8px;
      color: var(--text-light);

      .MuiCircularProgress-root {
        color: var(--text-light) !important;
      }
    }
  }

  > a:last-child {
    margin-left: 16px;
  }
}
