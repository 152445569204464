@tailwind base;
@tailwind components;
@tailwind utilities;

svg {
  display: inline;
}

.border-b {
  border-bottom: 1px solid #ededed;
}

.shadow-flat {
  box-shadow: 0px 1px 8px 0px rgba(54, 54, 54, 0.12);
}

.neumorphic-background {
  background: linear-gradient(180deg, #f9f9f9 0%, #f5f5f5 100%);
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer components {
  .custom-scrollbar {
    @apply scrollbar scrollbar-track-white scrollbar-thumb-gray-30 scrollbar-thin;
  }

  .resetButton {
    @apply appearance-none bg-transparent border-none m-0 text-left focus:outline focus:outline-cloud-40 w-full rounded-md;
  }
}
