.inlineRef {
  position: relative;
  top: -2px;
  font-size: var(--font-size-smallest);
  padding: 2px 6px;
  border-radius: 9999px;
  margin-left: 4px;
  color: var(--black);
  background-color: var(--background-active);
  border: 1px solid transparent;

  &:hover {
    border-color: var(--inline-ref-border-hover);
    background-color: var(--inline-ref);
  }
}
