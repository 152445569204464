.userImage {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &.empty {
    border: 1px solid;
    border-color: var(--border-darker);
    background-color: var(--background);
  }
}
