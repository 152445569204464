@use 'src/styles/functions/index' as *;

.sidebarContainer {
  cursor: default;
  min-width: 900px;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  min-height: 100%;
  position: relative;

  --page-transition-duration: var(--duration-fast);
  --background-fade-in-duration: var(--duration-medium);

  &:before {
    @include backgroundImage;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-attachment: fixed;

    background-image: var(--background-image);
    content: ' ';
  }

  &.withBackground {
    &:before {
      opacity: 1;
    }
  }

  h4 {
    font-weight: 500;
    margin-bottom: 0;
  }

  .sidebarContentContainer {
    display: flex;
    flex-grow: 1;
    position: relative;
    margin-left: 0;
    @include animate('margin-left');
    &.sidebarOpen {
      @include for-desktop-only {
        margin-left: var(--sidebar-width);
      }
    }
  }

  .sidebarContent {
    flex-grow: 1;
  }

  @include for-mobile-extended {
    .sidebarContent {
      min-width: 100%;
      width: 100%;
    }
  }

  .sidebarContent-enter {
    opacity: 0;
  }
  .sidebarContent-enter-active {
    opacity: 1;
    transition: opacity var(--page-transition-duration) ease-out;
  }
  .sidebarContent-exit {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }
  .sidebarContent-exit-active {
    opacity: 0;
    transition: opacity var(--page-transition-duration) ease-out;
  }

  .sidebarPage {
    display: flex;
    flex-direction: column;
    margin: 100px auto 0;
    max-width: 900px;
    min-width: 600px;
    padding: 0 32px 32px;
  }
}

@include for-mobile-extended {
  .sidebarContainer {
    position: absolute;
    min-width: 100%;
    width: 100%;
    margin-left: 0;

    .sidebarPage {
      width: 95%;
      min-width: 95%;
      max-width: 95%;
      margin: 65px auto;
      padding: 0;
    }
  }
}
