.resultItemFooter {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  min-height: 24px;
  font-size: var(--font-size-medium);
  column-gap: 12px;
  row-gap: 2px;
  color: var(--text-light);
  margin-top: 4px;

  .countIndicator {
    display: flex;
    align-items: center;
  }

  .times {
    .timestamp {
      display: flex;
      align-items: center;

      .timeLabel {
        margin-left: 4px;
      }
    }
  }
}
