@use './animations' as *;

@mixin _clampLines($lines) {
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin clampLines($lines) {
  @include _clampLines($lines);
  display: -webkit-box;
}

// Used when we want to show elipsis properly for multilined text.
@mixin clampLinesInline($lines) {
  @include _clampLines($lines);
  display: -webkit-inline-box;
}

@mixin anchor {
  @include animate('color');

  text-decoration: none;
  color: var(--text-link);

  &:hover {
    color: var(--text-link-hover);
  }
}

@mixin text14 {
  font-size: var(--font-size-medium);
  line-height: 21px;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
