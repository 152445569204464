@use 'src/styles/functions/index' as *;

.uiNotice {
  max-width: 800px;
  margin-top: 20px;
  border: 1px solid var(--primary-active);
  padding: 12px;
  color: var(--primary-active);
  @include roundedBoxMedium;
  font-size: var(--font-size-medium);
  line-height: 21px;
  display: block;
  background-color: transparent;
  &.centered {
    text-align: center;
  }
}
