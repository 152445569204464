input,
select,
optgroup,
textarea {
  line-height: 1.5;
}

input[type='radio'] {
  appearance: none;
  cursor: pointer;
  width: 14px;
  height: 14px;

  // Overrides the styles for radio buttons

  &:after {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--background);
    content: '';
    display: inline-block;
    visibility: visible;

    border: 1px solid var(--border-darker);
    transition: border var(--duration-fast), width var(--duration-fast),
      height var(--duration-fast);
  }

  &:hover:after,
  &:focus:after {
    border-color: var(--primary-light);
    background-color: var(--background-ghost);
  }

  &:checked:after {
    width: 8px;
    height: 8px;
    border-width: 4px;
    border-color: var(--primary);
    background-color: var(--background);
  }
}
