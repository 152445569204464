@use 'src/styles/functions/index' as *;

.searchInput {
  position: relative;

  .uiTextInput {
    @include animate(box-shadow);
  }

  .searchInputBox {
    color: var(--text) !important;
  }

  .tabFocusHint {
    position: absolute;
    right: 13px;
    top: 12px;
    color: var(--text-hint);
    font-size: var(--font-size-medium);
    > span {
      @include roundedBoxSmall();
      border: 1px solid var(--border-dark);
      padding: 2px 6px;
      margin-right: 3px;
    }
  }

  @include for-mobile-extended {
    .tabFocusHint {
      display: none;
    }
  }

  .topSearchInput {
    width: 100%;

    &.uiTextInput.autoSuggestOpen {
      background-color: var(--background);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-color: var(--border);
      box-shadow: var(--shadow-light);
    }
  }
}
