@use 'src/styles/functions/index' as *;

.listView {
  display: flex;
  flex-direction: row;
  .resultsListContainer {
    display: flex;
    flex-direction: column;
    width: 700px;
    flex-shrink: 0;

    .notices {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .resultsList {
      position: relative;

      hr {
        margin-right: 20px;
      }

      .discoverSimilar {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .noExactMatch {
        border-bottom: 1px solid var(--border);
        padding-bottom: 16px;
      }
    }
  }
}

@include for-mobile-extended {
  .listView {
    width: 100%;
    flex-direction: column;
    .resultsListContainer {
      width: 100%;
    }
  }
}
