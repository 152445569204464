@use 'src/styles/functions/index' as *;

.onboardingStepContainer {
  margin: 64px auto 0;

  h1 {
    margin-block: 0;
  }

  h2 {
    color: var(--text-light);
    font-weight: 500;
  }

  .container {
    margin: 40px auto;
    @include roundedBoxMedium;

    .containerContent {
      padding: 24px 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .title {
      font-size: var(--font-size-larger);
    }

    p {
      font-size: var(--font-size-medium);
      line-height: 21px;
      color: var(--text-light);

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .instructions {
      width: 100%;
    }

    .controls {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
    }
  }
}
