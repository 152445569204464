@use 'src/styles/functions/index' as *;

.uiSelect {
  cursor: pointer;
  display: inline-flex;
  user-select: none;

  .selectLabel {
    display: flex;
    align-items: center;
    color: var(--text-hint);
    font-size: var(--font-size-medium);
    font-weight: 500;
    padding: 6px 8px 6px 11px;
    @include roundedBoxMedium;
    white-space: pre-wrap;
    border: none;
    background: none;
    cursor: pointer;

    @include animate();

    &.withSelection {
      background-color: var(--background-button-highlight);
      color: var(--text);
    }

    &.title {
      color: var(--text);
      font-size: var(--font-size-large);
      font-weight: 500;
    }

    &:hover,
    &.open {
      background-color: var(--background-lightest);
      color: var(--text);
    }

    svg {
      margin-right: 7px;

      &.toggleIcon {
        margin: 0 0 0 4px;
      }
    }
  }
}

@include for-mobile-extended {
  .uiSelect {
    .selectLabel {
      white-space: nowrap;
    }
  }
}

.uiSelectDropdown {
  user-select: none;

  .MuiPopover-paper {
    margin-top: 5px;
    color: var(--text);
    font-size: var(--font-size-medium);
    cursor: default;

    max-height: 300px;
    overflow-y: auto;

    @include roundedBoxMedium;
    box-shadow: var(--shadow-light);
    border: 0.5px solid;
    border-color: var(--border);
  }

  .dropdownContent {
    // Override UITextbox styles
    .uiTextInput {
      width: 100%;
      background-color: transparent;
      // this selectors has lower specificity than `plain`.
      border: none !important;
      border-bottom: 1px solid !important;
      border-bottom-color: var(--border) !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      .iconContainer {
        margin-left: 11px;
      }
    }

    ul {
      @include resetList();

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 11px;
        cursor: pointer;

        span {
          margin-left: 6px;
        }

        &:hover {
          background-color: var(--background-lightest);
        }

        &.selected {
          font-weight: 500;
        }
      }
    }

    .uiSelectResultsContainer {
      // Tall enough for 10 results
      max-height: 336px;
      overflow-y: auto;
      border-top: 1px solid;
      border-top-color: var(--border);

      .searchEmpty {
        padding: 11px;
        font-size: var(--font-size-small);
        line-height: 18px;
        color: var(--text-light);
      }
    }
  }
}
