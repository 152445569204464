.featureFlagTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    color: var(--text);
    &:hover {
      text-decoration: underline;
    }
  }
}
