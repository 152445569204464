.billingPage {
  h3 {
    margin: 0;
  }

  .notice {
    font-size: var(--font-size-medium);
    line-height: 20px;
    .svgIcon {
      margin-right: 10px;
    }
    &.alert {
      color: var(--alert-active);
      border-color: var(--alert-active);
    }
  }
  hr {
    margin: 20px 0;
  }

  .uiButton {
    svg {
      margin-right: 5px;
    }
  }
}
