@use 'src/styles/functions/index' as *;

.toastsContainer {
  position: fixed;
  bottom: 90px;
  right: 38px;
  z-index: $zIndexToasts;
  cursor: default;

  .toast {
    align-items: flex-start;
    display: flex;
    margin: 15px 0 -10px;
    padding: 16px 18px 15px;
    position: relative;
    max-width: 600px;

    @include roundedBoxMedium;
    box-shadow: var(--shadow);
    background-color: var(--background);
    min-width: 340px;

    border: 1px solid;
    border-color: var(--border);

    // Used for TransitionGroup animation
    @include animate;
    opacity: 0;

    &.toastState-enter-done {
      opacity: 1;
      margin-bottom: 0;
    }

    &.toastState-exit {
      opacity: 0;
    }

    .toastIcon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      @include circle;

      &.success {
        background-color: var(--background-success);

        svg {
          color: var(--success);
        }
      }

      &.failure {
        background-color: var(--background-alert);

        svg {
          color: var(--alert);
        }
      }
    }

    &.dismissible {
      padding-right: 32px;
    }

    .toastMessage {
      margin: -2px 0 0 8px;
      font-size: var(--font-size-medium);
      font-weight: 500;
    }

    .closeIcon {
      position: absolute;
      right: 6px;
      top: 6px;
    }
  }

  @media (max-width: 640px) {
    right: 10px;
    left: 10px;
  }
}
