@use 'src/styles/functions/index' as *;

.loading {
  &.small {
    margin: 32px;
    gap: 16px;
  }

  &.medium {
    margin: 64px;
    gap: 24px;
  }

  &.large {
    margin: 128px;
    gap: 32px;
  }

  &.huge {
    font-size: var(--font-size-notice);
  }
  display: flex;
  align-items: center;
  vertical-align: middle;
  flex-direction: column;
  gap: 32px;

  color: var(--text-lighter);
  &.inline {
    align-items: center;
    margin: 0;
    flex-direction: row;
  }

  span {
    white-space: nowrap;
  }
}
