@use 'src/styles/functions/index' as *;

// Change behavior so that only textbox blur closes the popover
.autoSuggestPopover {
  background-color: var(--background);

  border: 1px solid var(--border);
  border-top: none;
  z-index: $zIndexAutosuggest;
  margin-top: -1px;
  border-radius: 0 0 8px 8px;
  box-shadow: var(--shadow-light);
  user-select: none;
  cursor: default;

  .searchHistoryPlaceHolder {
    display: flex;
    margin: 8px 4px;
    justify-content: center;
    color: var(--text-hint);
  }

  .recentVisitsSuggestionsPlaceholder {
    display: flex;
    font-size: var(--font-size-medium);
    margin: 2px 14px;
    justify-content: left;
    color: var(--text-hint);
  }
  .recentSearches {
    &:last-child .autosuggestItem:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .autosuggestItem .itemFilter {
      color: var(--text-lighter);
    }
  }
}
