@use 'src/styles/functions/index' as *;

.openSidebar {
  margin-left: 32px;
  position: fixed;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-top: 6px;
  color: var(--text-light);
  @include roundedBoxMedium;

  &:hover {
    background-color: var(--background-lightest);
  }

  > .companyLogo {
    margin-right: 4px;
  }

  &.hidden {
    pointer-events: none;
    visibility: hidden;
  }

  @include contrast {
    fill: var(--text);
    &:hover {
      background-color: var(--background-lightest);

      svg {
        color: var(--text-light);
      }
    }

    .companyLogo {
      margin-right: 4px;
    }

    color: var(--text-contrast);
  }
}

@include for-mobile-only {
  .openSidebar {
    margin-left: 8px;
    margin-top: 8px;
  }
}
