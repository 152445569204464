@use 'src/styles/functions/index' as *;

.backlinks {
  display: flex;
  flex-direction: column;
  gap: 6px;

  > .heading {
    font-size: var(--font-size-medium);
    color: var(--text-light);
    display: inline-flex;
    align-items: center;
  }

  > .uiButton {
    max-width: fit-content;
  }

  > .backlink {
    display: flex;
    gap: 6px;
    align-items: flex-start;

    > .svgIcon {
      flex-shrink: 0;
      margin-top: 4px;
    }

    > a {
      @include clampLines(2);
      @include ellipsis;
      word-break: break-word;
      color: var(--text);
      white-space: normal;
      font-size: var(--font-size-medium);

      &:hover {
        color: var(--text-link-hover);
      }
    }
  }
}
