/* stylelint-disable scale-unlimited/declaration-strict-value */
@use 'src/styles/functions/index' as *;

.notificationSidebar {
  top: 0;
  bottom: 0;
  transition: transform 0.3s ease;
  z-index: $zIndexNotificationSidebarDesktop;

  &.open {
    transform: translateX(var(--sidebar-width));
  }

  &.close {
    transform: translateX(-100%);
  }

  @media screen and (max-width: 768px) {
    z-index: $zIndexNotificationSidebarMobile;
    &.open {
      transform: translateX(0);
    }
  }

  a {
    color: inherit;
  }

  .notifications {
    .defaultAvatar {
      path {
        fill: #7c7c7c;
      }
    }
  }
}

.notificationMarkdown {
  p {
    margin: 0 0 12px;
  }

  blockquote {
    margin: 0;
    padding: 0 0 0 12px;
    color: #566172;
  }
}
