@use 'src/styles/functions/index' as *;

.messageResult {
  /* stylelint-disable-next-line scss/no-dollar-variables */
  $resultPadding: 12px;

  display: flex;
  color: inherit;
  padding: $resultPadding;
  margin-right: 20px;
  gap: 8px;
  @include roundedBoxMedium;

  &:hover {
    background-color: var(--background-lightest);
    color: inherit;

    .copyButton {
      visibility: visible;
    }
  }

  > .svgIcon {
    flex-shrink: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    width: 100%;
    .header {
      display: flex;
      gap: 4px;
      align-items: center;

      time {
        color: var(--text-light);
        font-size: var(--font-size-medium);
        white-space: nowrap;
      }

      .title {
        @include ellipsis;
        font-size: var(--font-size-larger);
        font-weight: 500;
        max-width: 50%;
        display: inline-block;
      }

      .direction {
        @include roundedBoxSmall;
        padding: 0 4px;
        background-color: var(--background-active);
        margin-left: auto;
      }

      .participant {
        @include ellipsis;
      }

      .participant,
      .direction {
        color: var(--text-light);
        font-weight: 500;
        font-size: var(--font-size-medium);
      }
    }

    .footer {
      .contentSnippet {
        color: var(--text-light);
      }
    }

    .copyButton {
      visibility: hidden;
      position: absolute;
      right: $resultPadding;
      bottom: $resultPadding;
      background-color: var(--background-lightest);
    }
  }
}
