@use 'src/styles/functions/index' as *;

.pageSearchResultItem {
  /* stylelint-disable-next-line scss/no-dollar-variables */
  $resultPadding: 12px;
  /* stylelint-disable-next-line scss/no-dollar-variables */
  $activeMargin: 20px;

  margin: 4px 0;
  overflow: hidden;
  outline: none;
  position: relative;

  &:focus-visible .activeContainer {
    border-color: var(--primary);
  }

  &.previewActive {
    .activeContainer {
      background-color: var(--background-lightest);
    }
  }

  &.collated {
    margin-left: 38px;
    margin-top: 0;
    margin-bottom: 0;

    .activeContainer {
      align-items: center;
      min-height: auto;
      padding: 6px 12px;
    }

    .collatedIcon {
      margin: -5px 8px 0 0;
    }

    .title {
      font-size: var(--font-size-larger);
      font-weight: 500;
      width: 140px;
      @include ellipsis;
    }

    .breadcrumbs {
      width: 200px;
    }

    .meta time {
      width: 110px;
    }

    .meta time,
    .actions {
      margin-left: 8px;
      text-align: right;
    }

    .actions {
      padding-left: 24px;
      top: 12px;
      align-items: center;
      justify-content: flex-end;
    }
  }

  // Used to provide a bit of a spacial gap between the result and its preview
  // without removing the hover target.
  .activeContainer {
    border: 1px solid transparent;
    display: flex;
    min-height: 75px;
    margin-right: $activeMargin;
    @include roundedBoxMedium;
    padding: $resultPadding;
    cursor: pointer;
  }

  // Shared with collated
  .meta {
    display: flex;
    align-items: center;
    color: var(--text-light);
    font-size: var(--font-size-medium);
    line-height: 18px;

    .divider {
      width: 1px;
      height: 12px;
      border-left: 1px solid;
      border-left-color: var(--border-darker);
      margin: 0 9px;
    }

    .appName {
      white-space: nowrap;
      font-weight: 500;
    }

    .breadcrumbs {
      @include ellipsis;

      svg {
        margin-top: -2px;
        path {
          fill: var(--text-hint);
        }
      }
    }
  }

  .resultLeft {
    flex: 0;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .resultFile,
    .resultFolder,
    .resultTask,
    .resultPerson,
    .resultCompany {
      margin-top: -4px;
    }
  }

  .resultRight {
    max-width: 100%;
    width: 100%;
    align-self: stretch;

    @include for-mobile-extended {
      width: 85%;
      max-width: 90%;
    }

    .titleContainer {
      display: flex;
      align-items: flex-start;
      margin-bottom: 4px;

      .title {
        font-size: var(--font-size-larger);
        font-weight: 500;
        margin: 0 2px 0 0;
        hyphens: auto;
        word-break: break-word;
        @include clampLines(2);
      }

      .status {
        margin: 2px 4px 0;
        border: 1px solid var(--primary);
        @include roundedBoxLarge;
        color: var(--primary);
        font-size: var(--font-size-smallest);
        padding: 0 6px;
        text-transform: capitalize;
      }
    }

    .meta {
      min-width: 0;
      max-width: 640px;
      margin-bottom: 4px;
      @include for-mobile-extended {
        max-width: 100%;
        word-wrap: break-word;
      }
    }

    .resultAttachments {
      margin: 8px 0;
    }

    .pinnedMessage {
      font-size: var(--font-size-medium);
      color: var(--text-light);
    }
  }

  .actions {
    position: absolute;
    bottom: $resultPadding;
    right: $resultPadding + $activeMargin;
    visibility: hidden;
  }

  &:hover,
  &:focus-within {
    .actions {
      visibility: visible;
    }
  }

  & + .debugInfo {
    font-family: monospace;
    font-size: var(--font-size-smallest);
  }

  @include for-mobile-extended {
    margin: auto;
    .activeContainer {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
