.uiTextInputContainer {
  .uiTextInput {
    .showWhiteField {
      background-color: var(--background);
    }

    .noResize {
      resize: none;
    }

    .controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      svg {
        width: 18px;
        margin-right: 10px;
      }
    }
  }
}
