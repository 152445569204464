.searchFilterSelector {
  .headerIcon {
    margin-top: -2px;
  }

  .selectLabel {
    .userImage {
      flex-shrink: 0;
      margin-right: 5px;

      &.empty {
        margin-top: -2px;
      }

      svg {
        margin-right: 0;
        opacity: 1;
      }
    }
  }
}

.searchFilterDropdown {
  .MuiPopover-paper {
    max-height: inherit;
  }

  .headerIcon {
    margin-top: -3px;
  }

  .searchContainer {
    // Adjusts clear button so it appears within the box
    input.withIcon {
      width: 146px;
    }
  }

  ul.searchResults {
    .userImage {
      &:not(.empty) {
        margin-top: 0;
        margin-bottom: -3px;
      }
    }
  }
}
