@use 'src/styles/functions/index' as *;

.imagePicker {
  display: flex;
  align-items: center;

  .imagePreview {
    min-width: 80px;
    height: 80px;
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    cursor: pointer;
    box-shadow: var(--shadow);
    border-color: var(--border);
    @include animate;
    @include roundedBoxMedium;

    &:hover {
      border-color: var(--border-dark);
    }

    img {
      height: 80px;
    }

    .imagePickerLabel {
      font-size: var(--font-size-small);
      color: var(--text-light);
      padding: 5px;
    }
  }
}

// SEE https://github.com/filestack/filestack-js/issues/367
/* stylelint-disable-next-line selector-class-pattern */
.fsp-notifications__container {
  position: fixed !important;
}
