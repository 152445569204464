@use 'src/styles/functions/index' as *;

.sidebar {
  @include animate('transform');
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  position: fixed;
  width: var(--sidebar-width);
  padding: 16px 0 8px;
  height: 100%;
  top: 0;
  z-index: $zIndexSidebar;
  transform: translateX(calc(var(--sidebar-width) * -1));
  user-select: none;
  border-right: 1px solid;

  border-right-color: var(--border);

  &.open {
    transform: translateX(0);
  }

  .companyHeader {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    padding: 0 8px 0 16px;

    .headerLeft {
      color: var(--text);
      display: flex;
      align-items: center;

      .companyName {
        margin-left: 8px;
        font-weight: 500;
        @include clampLines(2);
      }
    }

    svg {
      flex-shrink: 0;
      margin-right: 8px;
    }
  }

  .settingsSection {
    .settingsHeader {
      display: flex;
      flex-shrink: 0;
      flex-grow: 0;
      align-items: center;
      cursor: default;
      padding: 0 16px;

      .settings {
        color: var(--text);
        margin-left: 8px;
        font-weight: 500;
        @include clampLines(2);
      }
    }
  }

  .sidebarLink,
  .sidebarButton {
    margin: 1px 0;
    padding: 8px !important;
    font-weight: 500;

    &.active,
    &:hover {
      background-color: var(--background-active);
      color: var(--text);
    }

    .externalIcon {
      margin-left: auto;
    }

    .sidebarLinkIcon,
    .externalIcon {
      margin-top: -2px;
    }
  }

  // required for buttons that should look like links
  button.sidebarLink {
    border: none;
    width: 100%;
    font-size: inherit;
    background-color: transparent;
  }

  .sidebarButton {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-weight: 500;

    .sidebarLinkIcon {
      margin-right: 8px;
    }
  }

  .sidebarLinks {
    margin-top: 16px;
    height: 100%;
  }

  .linksChildren {
    padding: 0 12px 8px 32px;
    @include animate('height');

    &.hidden {
      height: 0;
    }
  }

  .linksSection {
    margin-top: 8px;
    border-top: 1px solid;
    border-top-color: var(--border);

    .sectionHeader {
      margin: 0 12px;

      .uiButton {
        width: 100%;

        .expandIcon {
          @include animate('transform');
          margin-left: auto;
          margin-right: 3px;
        }

        &.active .expandIcon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .sidebarFooter {
    flex-shrink: 0;
    flex-grow: 0;
  }
}
