@use 'src/styles/functions/index' as *;

.uiButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  @include roundedBoxSmall;
  vertical-align: bottom;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  border: 1px solid transparent;

  font-weight: 500;
  font-size: var(--font-size-medium);
  letter-spacing: 0.2px;

  @include animate;

  color: inherit;

  svg {
    margin-top: -1px;
  }

  .loader {
    margin-right: 5px;
    width: 15px;
  }

  // Progress bar
  .MuiCircularProgress-root {
    margin: -3px 6px 0 0;

    .MuiCircularProgress-circle {
      color: var(--text-contrast);
    }
  }

  .svgIcon:not(:only-child) {
    &:last-child {
      margin-left: 8px;
    }
    &:first-child {
      margin-right: 8px;
    }
  }

  &.tiny {
    font-size: var(--font-size-medium);
    padding: 3px 6px;
    @include roundedBoxSmall;

    .svgIcon {
      width: 14px;
      margin-top: 0;

      &:last-child {
        margin-left: 4px;
      }
      &:first-child {
        margin-right: 4px;
      }
    }
  }

  &.small {
    font-size: var(--font-size-medium);
    padding: 6px 8px;
  }

  &.large {
    font-size: var(--font-size-medium);
    padding: 10px 20px;

    svg {
      margin-top: 0;
    }
  }

  &.xLarge {
    font-size: var(--font-size-larger);
    padding: 16px 32px;
  }

  &.long {
    font-size: var(--font-size-medium);
    padding: 8px 48px;
  }

  &.primary {
    background-color: var(--primary);
    color: var(--text-contrast);

    svg:not(.preserveFill) path {
      fill: var(--text-contrast);
    }

    &:hover {
      background-color: var(--primary-hover);
    }

    &:active {
      background-color: var(--primary-active);
    }
  }

  &.secondary {
    background-color: var(--background);
    color: var(--text);

    border-color: var(--border-dark);

    &:hover {
      border-color: var(--border-darker);
    }

    &:active {
      background-color: var(--background-lightest);
    }
  }

  &.ghost {
    background-color: transparent;
    color: var(--text-hint);

    font-weight: 500;

    &:hover {
      background-color: var(--background-lightest);
    }

    &:active {
      background-color: var(--background-active);
    }
  }

  &.caution {
    color: var(--caution);
    background-color: transparent;

    border-color: var(--border-caution);

    &:hover {
      border-color: var(--border-caution-hover);
      color: var(--caution-hover);
    }

    &:active {
      border-color: var(--border-caution-active);
      color: var(--caution-active);
    }
  }

  &.delete {
    color: var(--alert);
    background-color: transparent;

    border-color: var(--border-dark);

    &:hover {
      border-color: var(--alert-hover);
      color: var(--alert-hover);
    }

    &:active {
      border-color: var(--alert-active);
      color: var(--alert-active);
    }
  }

  &.warning {
    background-color: var(--alert);
    color: var(--text-contrast);

    border-color: var(--alert);

    &:hover {
      background-color: var(--alert-hover);
      border-color: var(--alert-hover);
    }

    &:active {
      background-color: var(--alert-active);
      border-color: var(--alert-active);
    }
  }

  &.success {
    background-color: var(--background-success);
    color: var(--text-success);

    border-color: var(--background-success);

    &:hover {
      background-color: var(--success-active);
    }
  }

  &.processing {
    pointer-events: none;
  }

  &.disabled {
    pointer-events: none;
    border-color: var(--border-dark);
    opacity: 0.4;
  }

  &.status {
    border: none;
    background-color: transparent !important;
    cursor: default;
    font-weight: 400;
  }

  &.badge {
    border: 1px solid;
    border-color: var(--background-primary-light);
    background-color: var(--background-primary-light);
    cursor: default;
  }

  &.flat {
    border: none;
    background-color: var(--background-button);
    color: var(--text-light);
    &:hover {
      background-color: var(--background-active);
    }
  }
}
