@use 'src/styles/functions/index' as *;

* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Ubuntu, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  // stylelint-disable-next-line property-no-unknown
  font-smoothing: always;
}

b,
strong {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1 {
  font-size: var(--font-size-heading);
}

a {
  @include anchor;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}
