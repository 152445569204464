@use 'src/styles/functions/index' as *;

.pinnedResultsContainer {
  margin: 20px 0;

  &:after {
    content: '';
    display: block;
    margin-top: 20px;
    width: calc(100% - 20px);
    height: 1px;
  }

  .title {
    font-weight: 500;
    cursor: default;
  }
}
