@use 'src/styles/functions/index' as *;

.searchSuggestionContainer {
  @include roundedBoxMedium;

  color: var(--text-light);
  border: 1px solid var(--border);
  padding: 0 16px;
  justify-content: space-between;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;

  .app {
    display: inline-flex;
    align-items: baseline;

    .svgIcon {
      align-self: center;
    }
  }
}
