@use 'src/styles/functions/index' as *;

.previewItem {
  border: 1px solid;
  border-color: var(--border);
  box-shadow: var(--shadow-light);
  @include roundedBoxMedium;
  cursor: default;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: $zIndexResultPreview;
  background-color: var(--background);
  display: flex;
  flex-direction: column;

  .hidden {
    display: none;
  }

  .heavier {
    font-weight: 500;
  }

  .lighter {
    color: var(--text-light);
  }

  .status {
    border: 1px solid var(--primary);
    @include roundedBoxLarge;
    color: var(--primary);
    font-size: var(--font-size-smallest);
    padding: 1px 6px;
    text-transform: capitalize;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    font-weight: 500;
    font-size: var(--font-size-larger);
    border-bottom: 1px solid;
    border-bottom-color: var(--border);

    .prefixIcon {
      margin-right: 8px;
    }

    span {
      width: 100%;
      font-size: var(--font-size-larger);
      font-weight: 500;
      @include ellipsis;
      margin-right: 15px;
    }
  }

  .workingContainer {
    padding: 40px;
    text-align: center;
    color: var(--text-lighter);
  }

  .previewIframe {
    border-bottom: 1px solid;
    border-bottom-color: var(--border);
  }

  .thumbnailImage {
    padding: 0 6px;
    width: 100%;
  }

  .richText {
    padding: 12px 16px;
    font-size: var(--font-size-medium);
    line-height: 18px;
    overflow: auto;
    max-height: 300px;
    border-bottom: 1px solid;
    border-bottom-color: var(--border);

    ul {
      padding-left: 20px;
      margin: 10px 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: var(--font-size-larger);
      margin: 0 0 15px;
      padding: 0;
    }
  }

  .footer {
    padding: 12px 16px;
    overflow-x: auto;

    > .backlinks {
      margin-top: 8px;
    }

    ul {
      @include resetList();

      li {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
