@use 'src/styles/functions/index' as *;

.homepageLinkDialog {
  .MuiPaper-root {
    width: 660px;
  }

  .uiTextboxContainer {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 8px;
    }
  }

  .modalActions {
    .uiButton {
      margin-right: 8px;
    }
  }
}
