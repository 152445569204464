@use 'src/styles/functions/index' as *;

.contentSnippet {
  margin: 4px 0 0;
  font-size: var(--font-size-medium);
  line-height: 20px;
  @include clampLines(2);
  word-break: break-word;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: var(--font-size-medium);
    line-height: 20px;
    margin: 0;
    padding: 0;
  }

  * {
    max-width: 100%;
  }
}
