@use 'src/styles/functions/index' as *;

.personCard {
  box-shadow: var(--shadow);
  @include roundedBoxMedium;
  margin: 10px 20px 30px 0;
  cursor: default;

  .personHeader {
    display: flex;
    padding: 24px;

    .personImage {
      margin-right: 20px;
    }

    .personRight {
      // If right side is short, we align center
      align-self: center;

      .personName {
        font-size: var(--font-size-largerer);
        line-height: 30px;
      }

      .titleRow {
        @include text14;
        color: var(--text-light);
        margin: 8px 0;

        .personTitle {
          font-weight: 500;
        }

        .personSince,
        .personLocation {
          margin-left: 8px;
          padding-left: 8px;
          border-left: 1px solid var(--border-dark);

          &:first-child {
            margin-left: 0;
            padding-left: 0;
            border-left: none;
          }
        }
      }

      .handleRow {
        @include text14;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        flex-wrap: wrap;

        .appHandle {
          color: var(--primary);
          display: flex;
          align-items: center;
          cursor: pointer;
          margin: 0 12px 8px 0;

          span {
            @include anchor;
            margin-left: 3px;
          }
        }
      }

      .phoneNumber,
      .localTime {
        display: inline-block;
        margin-right: 20px;
        color: var(--text-light);
        @include text14;
      }
    }
  }
  .activities {
    padding-left: 24px;
    padding-bottom: 2px;
    h4 {
      font-size: var(--font-size-small);
      font-weight: 500;
      color: var(--text-light);
    }
    ul {
      font-size: var(--font-size-medium);
      padding-left: 5px;
      list-style-type: none;
      li {
        margin-bottom: 4px;
        a {
          font-weight: 500;
        }
      }
    }
  }
}
