@use 'src/styles/functions/index' as *;

.topHeader {
  width: 100%;
  border-bottom: 1px solid;
  border-bottom-color: var(--border);
  position: fixed;
  top: 0;
  z-index: $zIndexPageSearchTopHeader;

  background-color: var(--background);

  &.hideBorder {
    border-bottom: none;
  }

  &.transparent {
    background-color: transparent;

    header {
      background-color: transparent !important;
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    background-color: var(--background);
    z-index: $zIndexTopHeaderHeader;

    .left {
      display: flex;
      align-items: center;

      .searchInputContainer {
        width: 700px;
        @include animate;
        display: inline;
        flex-wrap: wrap;
      }
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: -3px;

      button {
        margin: 0 15px 6px 0;
      }
    }
  }
}

@include for-mobile-extended {
  .topHeader {
    width: 100%;
    header {
      justify-content: flex-start;
      margin-left: 19%;
      margin-right: 5%;
      gap: 0;
      padding-top: 1.5vh;
      .left {
        .searchInputContainer {
          width: 100%;
        }
        flex-grow: 100;
      }
      .right {
        display: none;
        width: 0;
      }
    }
  }
}
