.appFilterUISelect,
.appFilterUISelectDropdown {
  // Adjust icons
  svg {
    margin-top: -2px;
  }

  svg.allAppsIcon {
    margin-top: -4px;
  }
}
