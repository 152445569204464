@use 'src/styles/functions/index' as *;

.expandableListContainer {
  .expandableListTitle {
    cursor: pointer;
    font-weight: 500;
    color: var(--text-purple);
    display: flex;
    justify-content: space-between;
  }
  .expandableListItems {
    margin: 4px 0;
    padding: 0;

    li {
      cursor: pointer;
      margin: 6px 0;
      list-style-type: none;
      display: flex;
      gap: 10px;
      align-items: center;
      a {
        @include ellipsis;
      }
    }
    .relatedSearchItem:hover {
      transition: 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      color: var(--text-light);
    }
  }
}
