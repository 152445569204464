.appInstallDialog {
  p.firstNotice {
    margin: 0 0 24px;
    font-size: var(--font-size-medium);
    line-height: 21px;
    color: var(--text-light);
  }

  // Dialog content
  .MuiPaper-root {
    width: 680px;
    max-width: 680px;

    .content {
      max-height: 640px;
    }
  }
}
