/* stylelint-disable scss/no-dollar-variables */
@use 'sass:map';

// Reference: https://www.figma.com/file/iRkzH0yqV7a9VgpYmxHm3X/Colors?node-id=0%3A1
$colors: (
  amethys: (
    10: #7d3c7f,
    20: #4f0d52,
    30: #270029,
  ),
  mahogany: (
    10: #ff6660,
    15: #c2410c,
    20: #cf3135,
    30: #970010,
  ),
  sunrise: (
    10: #ffb252,
    15: #fdba74,
    20: #dd973a,
    30: #965500,
  ),
  oak: (
    10: #ffddcf,
    20: #e8aa9d,
    30: #b47c70,
  ),
  moonlight: (
    10: #ffffff,
    20: #e5e1dc,
    30: #b3aea9,
  ),
  cloud: (
    10: #f7f7f7,
    15: #ededed,
    20: #c4c4c4,
    30: #949494,
    40: #7c7c7c,
  ),
  night: (
    10: #3d428c,
    20: #001c5e,
    30: #000034,
  ),
  Sapphire: (
    10: #026274,
    20: #458fa2,
    30: #003748,
  ),
  sky: (
    10: #5ec4f7,
    20: #0d94c4,
    30: #006693,
  ),
  ocean: (
    10: #c9ffff,
    20: #96dbdb,
    30: #65a9a9,
  ),
  smoke: (
    10: #4c4648,
    20: #241f21,
    30: #000000,
  ),
  moss: (
    10: #e4fcdd,
    20: #51cf66,
    30: #009d38,
  ),
  dashworksPink: (
    10: '#EFEBF0',
    50: '#E5D8E5',
  ),
);

@function colorShade($key, $shade) {
  $family: map.get($colors, $key);
  @if not $family {
    @error 'ERROR: Color family does not exist';
  }

  $colorValue: map.get($family, $shade);
  @if not $colorValue {
    @error 'ERROR: Shade does not exist';
  }

  @return $colorValue;
}

// Common class to use with dark backgrounds.
@mixin contrast() {
  &.contrast {
    @content;
  }
}
