@use 'src/styles/functions/index' as *;

.answerDialog {
  .MuiPaper-root {
    width: 740px;
    max-width: 740px;
    min-height: 400px;

    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .textEditor {
        flex-grow: 1;
      }

      .title {
        color: var(--text);
        padding: 0;
        background-color: var(--background);
      }
      .qaLabel {
        display: inline-block;
        background-color: var(--background-active);
        font-size: var(--font-size-small);
        font-weight: 500;
        padding: 2px 4px;
        border-radius: 2px;
      }
      .answerLabelContainer {
        margin-top: 10px;
      }
      .ql-toolbar,
      .ql-container {
        border: 1px solid var(--border) !important;
      }
      .ql-container {
        padding: 10px 0 !important;
        .ql-editor {
          padding: 0 10px;
          &.ql-blank::before {
            left: 10px !important;
          }
        }
      }
    }
  }
}
