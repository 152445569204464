@use 'src/styles/functions/index' as *;

.qaMessageList {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  flex: 111;

  /* stylelint-disable-next-line selector-class-pattern */
  .infinite-scroll-component {
    overflow: visible !important;
  }
  .stickyDivider {
    position: sticky !important;
    top: 0;
    background-color: inherit; /* This ensures the background color is the same as the parent */
  }
}
