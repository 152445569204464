@use 'src/styles/functions/index' as *;

.notice {
  margin-top: 20px;
  border: 1px solid;
  padding: 12px;
  border-color: var(--border);
  color: var(--primary-active);
  @include roundedBoxMedium;
  font-size: var(--font-size-medium);
  line-height: 21px;
}
