@use 'src/styles/functions/index' as *;

.emptyResults {
  font-size: var(--font-size-medium);
  margin: 10px 0;

  svg {
    color: var(--text-lighter);
  }

  p {
    margin: 15px 0;
  }

  .suggestions {
    margin-top: 20px;
    color: var(--text-light);

    ul {
      padding-left: 22px;
    }
  }
}
@include for-mobile-extended {
  .emptyResults {
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }
}
