@use 'src/styles/functions/index' as *;

:root {
  --alert-active: #{colorShade('mahogany', 20)};
  --alert-hover: #{colorShade('mahogany', 20)};
  --alert: #{colorShade('mahogany', 20)};
  --background-active-light: #f3f3f3;
  --background-active: #{colorShade('cloud', 15)};
  --background-alert: #{colorShade('oak', 10)};
  --background-button-highlight: #{colorShade('ocean', 10)};
  --background-button: #{colorShade('cloud', 10)};
  --background-contrast: #333;
  --background-ghost: #{rgba(#fff, 0.24)};
  --background-highlight: #{rgba(colorShade('sunrise', 10), 0.16)};
  --background-lightest: #{colorShade('cloud', 10)};
  --background-neutral-light: #{colorShade('moonlight', 20)};
  --background-ocean-regular: #{colorShade('ocean', 20)};
  --background-purple: #{colorShade('amethys', 20)};
  --background-onboarding: #e4e1dd;
  --background-primary-light: #{colorShade('oak', 10)};
  --background-success: #{colorShade('moss', 10)};
  --background: #fff;
  --beta-background-color: #d3eeff;
  --beta-primary-color: #328afe;
  --black: #000000;
  --white: #ffffff;
  --border-dark: #{colorShade('cloud', 20)};
  --border-darker: #{colorShade('cloud', 30)};
  --border-emphasis: #{colorShade('smoke', 20)};
  --border-caution: #{colorShade('sunrise', 15)};
  --border-caution-hover: #{colorShade('sunrise', 20)};
  --border-caution-active: #{colorShade('sunrise', 20)};
  --border: #{colorShade('cloud', 15)};
  --caution-active: #{colorShade('mahogany', 30)};
  --caution-hover: #{colorShade('mahogany', 30)};
  --caution: #{colorShade('mahogany', 15)};
  --code: #{colorShade('mahogany', 20)};
  --color-answer: #{colorShade('moss', 20)};
  --color-checkbox-checked: #{colorShade('amethys', 20)};
  --color-doc: #{colorShade('sky', 20)};
  --color-pdf: #{colorShade('mahogany', 10)};
  --color-sheet: #{colorShade('moss', 20)};
  --color-slide: #{colorShade('sunrise', 10)};
  --date-filter-focus: #e9f3ff;
  --date-filter-range: #eef5ff;
  --divider: #b55ab840;
  --inline-ref: #f3e8ff;
  --inline-ref-border-hover: #d8b4fe;
  --primary-active-background: #{rgba(colorShade('amethys', 20), 0.1)};
  --primary-active-light-background: #{rgba(colorShade('amethys', 10), 0.1)};
  --primary-active-lightest-background: #{rgba(colorShade('amethys', 10), 0.05)};
  --primary-active: #{colorShade('amethys', 10)};
  --primary-hover: #{colorShade('amethys', 10)};
  --primary-light: #{colorShade('amethys', 10)};
  --primary: #{colorShade('amethys', 20)};
  --shadow-dark-color: rgba(0, 0, 0, 0.32);
  --shadow-dark: 2px 2px 16px var(--shadow-dark-color);
  --shadow-inset: inset 0 0 5px #1b1f23;
  --shadow-light: 0 2px 16px 4px rgba(0, 0, 0, 0.04);
  --shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  --success-active: #{colorShade('moss', 20)};
  --success: #{colorShade('moss', 20)};
  --interactive-pink: #{colorShade('dashworksPink', 50)};

  // Use with dark background
  --text-contrast: #fff;
  --text-hint: #{colorShade('cloud', 30)};
  --text-light: #{colorShade('cloud', 40)};
  --text-lighter: #{colorShade('cloud', 20)};
  --text-link-hover: #{colorShade('sky', 30)};
  --text-link: #{colorShade('sky', 20)};
  --text-success: #{colorShade('moss', 30)};
  --text-failure: #{colorShade('mahogany', 20)};
  --text: #{colorShade('smoke', 20)};
  --text-purple: #{colorShade('amethys', 10)};
  --text-yellow: #{colorShade('sunrise', 20)};
  --topic-pill-background: #f0ebf0;

  --duration-fast: 0.15s;
  --duration-medium: 0.3s;

  --loading-shimmer-neutral: #{colorShade('cloud', 10)};
  --loading-shimmer-contrast: #{colorShade('cloud', 20)};

  --sidebar-width: 245px;

  --font-size-smallest: 10px;
  --font-size-small: 12px;
  --font-size-medium: 14px;
  --font-size-larger: 16px;
  --font-size-largerer: 20px;

  --font-size-heading: 32px;
  --font-size-sub-heading: 24px;

  --font-size-notice: 18px;
  --font-size-hero-heading: 56px;
  --font-size-thicc-emoji: 34px;
  --font-size-button-emoji: 26px;

  --border-active: #{colorShade('amethys', 10)};

  --homepage-main-column-width: 884px;

  @include for-mobile-extended {
    // For mobile screens
    --font-size-smallest: 12px;
    --font-size-small: 14px;
    --font-size-medium: 14px;
    --font-size-larger: 16px;
    --font-size-largerer: 18px;

    --font-size-heading: 20px;
    --fonts-size-sub-heading: 18px;

    --font-size-hero-heading: 40px;
  }
}
