@use 'src/styles/functions/index' as *;

/* used for cloud search results, since some content might include mark values */
.highlight {
  font-style: normal;
  background-color: var(--background-highlight);
  color: var(--text);
  float: inherit;
  margin: 0;
}
