@use 'src/styles/functions/index' as *;

.uiTabs {
  .MuiTabs-root {
    .MuiTabs-flexContainer {
      gap: 20px;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .MuiTabs-indicator {
      background-color: var(--background-contrast);
    }

    .MuiTab-root {
      padding: 0;
    }

    .Mui-selected {
      color: var(--text);
      font-weight: 600;
    }
  }

  .uiTabPanels {
    padding: 16px 0;
  }
  @include for-mobile-extended {
    .uiTabPanels {
      width: 100%;
      overflow: scroll;
      white-space: nowrap;
    }
  }
}
