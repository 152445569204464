@use 'src/styles/functions/index' as *;

.resultFilters {
  margin: 20px 0 10px;
  display: flex;
  align-items: center;

  > * {
    margin-right: 10px;
  }
}

@include for-mobile-extended {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .resultFilters::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .resultFilters {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    width: auto;
    margin: 5px 0;
    align-items: start;
    overflow: scroll;
    white-space: nowrap;
  }
}
