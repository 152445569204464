@use 'src/styles/functions/index' as *;

.personCardDetails {
  padding: 24px;
  border-top: 1px solid var(--border);

  .detailsSummary {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .uiButton {
      padding-top: 9px;
    }

    .left {
      display: flex;
      gap: 40px;
    }

    .detailsBox {
      .boxTitle {
        font-size: var(--font-size-small);
        font-weight: 500;
        color: var(--text-light);
        margin-bottom: 4px;
      }

      .boxValue {
        font-size: var(--font-size-medium);
        font-weight: 500;
      }

      .managerBox {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        span {
          @include anchor;
        }

        .userImage {
          margin-bottom: 1px;
        }
      }

      .teamMembers {
        display: flex;
        align-items: center;

        .reportFaces {
          display: flex;
          align-items: center;
          margin-left: 5px;

          .userImage {
            margin-left: -5px;
          }
        }

        .moreReports {
          margin-left: 8px;
          margin-bottom: -2px;
          font-weight: 400;
          font-size: var(--font-size-small);
        }
      }
    }
  }

  .reportsExpanded {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 12px;
    column-gap: 12px;

    .reportPerson {
      background-color: transparent;
      border: none;
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      padding: 12px;
      @include roundedBoxMedium;
      @include animate('background-color');

      &:hover {
        background-color: var(--background-active-light);
      }

      .reportPersonDetails {
        text-align: left;

        .reportName {
          font-weight: 500;
          font-size: var(--font-size-medium);
        }

        .reportTitle {
          color: var(--text-light);
          font-weight: 500;
        }
      }
    }
  }
}
