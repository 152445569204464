@use 'src/styles/functions/index' as *;

.resultAttachment {
  margin-right: 4px;

  .attachmentName {
    margin-left: 4px !important;
    max-width: 125px;
    @include ellipsis;

    .highlight {
      margin: 0;
    }
  }
}
