@use 'src/styles/functions/index' as *;

.filterBarItem {
  display: inline-flex;
  align-items: center;
  padding: 2px 4px 6px;
  cursor: pointer;
  color: var(--text-hint);
  @include animate();
  user-select: none;
  border-bottom: 2px solid transparent;
  border: none;
  background: none;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    color: var(--text);
  }

  &.active {
    border-bottom: 2px solid;
    border-bottom-color: var(--text);
    pointer-events: none;

    color: var(--text);

    svg path {
      fill: var(--text);
    }
  }

  svg {
    margin-top: -2px;
  }

  .title {
    margin-left: 8px;
    font-size: var(--font-size-medium);
    font-weight: 500;
  }

  .count {
    font-size: var(--font-size-small);
    margin-left: 8px;
  }
}
