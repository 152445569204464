@use 'src/styles/functions/index' as *;

.homepageLinks {
  margin-top: 40px;
  margin-bottom: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  // Fixes a bug where autosuggest does not display correctly on load
  min-height: 125px;

  .linksHeader {
    font-size: var(--font-size-larger);
    margin-bottom: 12px;
    font-weight: 500;
    align-self: start;
  }

  .linksContainer {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 8px;

    &.sortActive {
      .sortableLinks {
        background-color: var(--background-ghost);
      }

      .homepageLinkContainer {
        pointer-events: none;
      }
    }

    .sortableLinks {
      display: flex;
      flex-flow: wrap;
      justify-content: flex-start;
      align-items: stretch;
      gap: 8px;
      @include roundedBoxMedium;
      @include animate;
    }
  }

  @include for-mobile-extended {
    .linksContainer {
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      flex-wrap: wrap;
      gap: 0;
      .sortableLinks {
        gap: 0;
        flex-wrap: wrap;
      }
    }
  }

  @include contrast {
    color: var(--text-contrast);

    .linksHeader {
      text-shadow: var(--shadow-dark);
    }

    .homepageLinkContainer {
      .homepageLinkIcon {
        stroke: var(--text-contrast);
      }

      .iconContainer {
        background-color: var(--background);
      }

      &:hover {
        background-color: var(--background-ghost);
        backdrop-filter: blur(16px);
      }
    }

    .homepageLinkTitle {
      color: var(--text-contrast) !important;
    }
  }

  // On white or almost white background
  &.light {
    .iconContainer {
      background-color: var(--background-lightest);
    }

    .linksContainer.sortActive .sortableLinks {
      background-color: var(--background-active);
    }
  }

  .homepageLinkContainer {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 126px;
    height: 126px;
    flex-grow: 0;
    flex-shrink: 0;
    @include roundedBoxMedium;

    &:hover,
    &.menuOpen {
      background-color: var(--background-ghost);

      .iconContainer {
        box-shadow: var(--shadow-dark);
      }

      .linkMore,
      .linkDragHandle {
        visibility: visible;
        transition: visibility 0s 0.4s;
      }
    }
  }
  @include for-mobile-extended {
    .homepageLinkContainer {
      width: auto;
      height: auto;
    }
  }

  .homepageLink {
    padding: 6px;
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: none;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 100%;

    font-size: var(--font-size-larger);
    line-height: 1.5;
    text-align: center;

    .iconContainer {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--background-lightest);
      @include roundedBoxMedium;
      @include animate;

      img {
        width: 20px;
      }

      svg {
        display: block;
      }
    }

    .homepageLinkIcon {
      width: 24px;
    }

    .homepageLinkTitle {
      @include clampLines(2);
      margin-top: 8px;
      font-size: var(--font-size-medium);
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      color: var(--text);
      word-break: break-word;
    }
  }
  @include for-mobile-extended {
    .homepageLink {
      padding: 0;
      height: 75px;
      width: 65px;
      gap: 7px;
      justify-content: start;
      .iconContainer {
        height: 32px;
        width: 32px;
      }

      .homepageLinkIcon {
        width: auto;
      }

      .homepageLinkTitle {
        margin-top: 0;
        padding-bottom: 0;
        font-size: var(--font-size-smallest);
      }
    }
  }

  .linkDragHandle {
    position: absolute;
    visibility: hidden;
    top: 4px;
    left: 8px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .linkMore {
    position: absolute;
    visibility: hidden;
    top: 8px;
    right: 8px;
    @include roundedBoxSmall;
    border: 1px solid transparent;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
    background-color: var(--background-button);

    &:hover {
      background-color: var(--background-active);
    }
  }

  .homepageLinkMenu {
    margin-top: 4px;
  }
}
@include for-mobile-extended {
  .homepageLinks {
    margin-top: 0;
  }
}
