@use 'src/styles/functions/index' as *;

.connectMoreAppsOuterContainer {
  margin-top: 16px;

  .connectMoreAppsInnerContainer {
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;

    .connectMoreApps {
      display: flex;
      justify-content: center;
      align-items: center;

      .appsDividerContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        .instantApps {
          display: flex;

          .instantApp {
            margin: 0 6px;
            padding-bottom: 4px;
          }
        }
        .divider {
          border-left: 1px solid var(--border);
          height: 36px;
          margin: 0 16px 0 10px;
        }
      }

      .connectMoreAppsButtonContainer {
        position: relative;
        .connectMoreAppsButton {
          padding: 8px;
        }

        .errorIcon {
          position: absolute;
          top: -10px;
          right: -10px;
        }
      }
    }
  }
}
