@use 'src/styles/functions/index' as *;

.sidebarOpen {
  .pageSearchContainer {
    &.resultsView {
      margin-left: 16px;
    }
  }
}

.pageSearchContainer {
  @include animate('margin-left');
  height: 100%;

  &.resultsView {
    margin-left: 128px;
  }

  .mainSearch {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

@include for-mobile-extended {
  .pageSearchContainer {
    &.resultsView {
      margin-left: 0;
      width: 100%;
    }
  }
}
