@use 'src/styles/functions/index' as *;

/* stylelint-disable-next-line scss/no-dollar-variables */
$button-width: 130px;

.recommendedAppsContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 6px;
  flex-flow: wrap;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  .recommendedAppsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 6px 12px;
    width: 40%;
    max-width: 50%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;

    @include for-mobile-only {
      width: 100%;
      max-width: 100%;
    }
  }

  .colSocialProof {
    display: flex;
    @for $i from 1 through 10 {
      /* Adjust the number as needed */
      > :nth-child(#{$i}) {
        position: relative;
        z-index: $zIndexSocialUserIcons - $i;
      }
    }
    .userIcons {
      margin-right: -5px;
    }

    .socialProofCount {
      margin-left: 8px;
      font-size: var(--font-size-medium);
      align-self: center;
    }

    @include for-mobile-only {
      display: none;
    }
  }

  .connectButton {
    width: $button-width;
  }

  .indexingApp,
  .disablingApp,
  .connectedApp,
  .upcomingApp,
  .connectionIssue {
    display: inline-flex;
    align-items: center;
    font-size: var(--font-size-medium);
    font-weight: 500;
    padding: 7px 0;
    width: $button-width;

    span {
      margin-left: 8px;
    }

    &.connectionIssue {
      color: var(--alert);

      span {
        margin-left: 4px;
      }
    }

    &.indexingApp {
      color: var(--primary-active);

      .MuiCircularProgress-circle {
        color: var(--primary-active);
      }
    }

    &.connectedApp {
      color: var(--text-success);
    }

    &.disablingApp {
      color: var(--alert);

      .MuiCircularProgress-circle {
        color: var(--alert);
      }
    }

    &.upcomingApp {
      color: var(--text-lighter);

      svg {
        margin-top: -1px;
      }
    }
  }

  .comingSoon,
  .notRequired,
  .notAvailable,
  .notConnected {
    width: $button-width;
    font-size: var(--font-size-medium);
    font-weight: 500;
    display: inline-block;
    color: var(--text-lighter);
  }

  .flipHover {
    .flipActive {
      display: none;
    }

    &:hover {
      .flipNormal {
        display: none;
      }

      .flipActive {
        display: inline-block;
      }
    }
  }

  @include for-mobile-only {
    flex-direction: column;
  }
}
