@use 'src/styles/functions/index' as *;

.answerIcon {
  color: var(--color-answer);
}

.infoIcon {
  margin-bottom: 3px;
  margin-left: 8px;
  color: var(--alert);
}
