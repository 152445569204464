@use 'src/styles/functions/index' as *;

.resultsGridContainer {
  width: 100%;
  .pinnedGrid,
  .resultGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-auto-flow: dense;

    justify-items: stretch;
    column-gap: 16px;
  }
  .pinnedGrid {
    grid-template-rows: 400px;
  }
  .resultGrid {
    grid-auto-rows: 400px;
    row-gap: 16px;
  }

  .pinnedResultsContainer > .title {
    margin-bottom: 16px;
  }
}
