.membersPage {
  .memberTabs {
    .MuiTabs-flexContainer {
      gap: 40px !important;
    }
    .MuiTab-root {
      min-width: 0;
      .MuiTab-wrapper {
        text-transform: none;
        font-size: var(--font-size-larger);
      }
    }
  }
}
