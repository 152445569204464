@use 'src/styles/functions/index' as *;

.previewContainer {
  width: 340px;
  position: relative;
}

@include for-mobile-extended {
  .previewContainer {
    display: none;
  }
}
