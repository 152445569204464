@mixin for-mobile-only {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin for-mobile-extended {
  @media (max-width: 1175px) {
    @content;
  }
}

@mixin for-desktop-only {
  @media (min-width: 1175px) {
    @content;
  }
}
