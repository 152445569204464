@use 'src/styles/functions/index' as *;

.filterBar {
  position: relative;
  width: 50%;
  gap: max(12px, 4%);
  display: flex;
  z-index: -1;
  transition: top var(--duration-fast) ease-in-out,
    max-height var(--duration-fast) ease-in-out;
  max-height: 40px;
  top: 0;

  @include for-desktop-only {
    &.outOfScroll {
      max-height: 0 !important;
      top: -40px;
    }
  }
}

@include for-mobile-extended {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .filterBar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .filterBar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    width: 100%;
    height: 45px;
    max-height: 50px;
    overflow: scroll;
    white-space: nowrap;
    margin-left: 5px;
  }
}
