@use 'src/styles/functions/index' as *;

.uiFloatingMenu {
  @include roundedBoxMedium;

  ul {
    @include resetList();
  }

  li {
    padding: 6px 11px;
    cursor: pointer;
    font-size: var(--font-size-medium);

    &:hover {
      background-color: var(--background-lightest);
    }
  }
}
