@use 'src/styles/functions/index' as *;

.membersTable {
  margin: 0 auto;

  .searchMembers {
    margin: 10px auto;

    .textSearchMembers {
      width: 100%;
    }
  }

  table {
    margin: 10px 0;
    td,
    th {
      padding-left: 8px;
    }

    .colName {
      height: 70px;
      font-weight: 500;
    }
    .colAction {
      width: 170px;
      text-align: right;
      padding-right: 5px;
    }

    td.colEmail {
      color: var(--text-light);
    }

    th.colRole {
      padding-left: 20px;
    }

    .colRole {
      width: 120px;

      .ownRole {
        padding: 6px 8px 6px 11px;
        color: var(--text-hint);
        font-weight: 500;
      }
    }
    .removeButton {
      visibility: hidden;
    }

    tbody {
      tr {
        height: 70px;
      }
      tr:hover {
        background-color: var(--background-active);
        .removeButton {
          visibility: visible;
        }
      }
    }
  }
  .inviteButton {
    margin-top: 10px;
    .emailText {
      text-decoration: underline;
    }
  }

  .inviteEmailMessage {
    color: var(--text-light);
    font-size: var(--font-size-small);
  }

  .selectedActionBar {
    position: fixed;
    bottom: 0;
    height: 50px;
    background: var(--background);
    width: 850px;
    border: 1px solid var(--border);
    border-bottom: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .actionSuccessText {
    display: inline-block;
    padding: 0 2px;
    font-size: var(--font-size-small);
    background-color: var(--background-success);
  }
}
