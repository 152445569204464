.markdownParser {
  & > :last-child {
    display: inline;
  }
  ul,
  ol {
    margin: 0 0 0 10px;
    padding: 0;
  }
  li {
    margin-left: 10px;
    padding-bottom: 4px;
  }
  p {
    padding-bottom: 8px;
    margin: 0;
    white-space: pre-line;
  }
  p:last-child {
    padding-bottom: 0;
  }

  code {
    font-family: 'SFMono-Regular', 'Inconsolata', 'Monaco', 'Consolas',
      'Andale Mono', 'Bitstream Vera Sans Mono', 'Courier New', Courier,
      monospace;
    font-size: var(--font-size-medium);
    white-space: pre-wrap;
  }

  .codeBlock * {
    font-family: 'SFMono-Regular', 'Inconsolata', 'Monaco', 'Consolas',
      'Andale Mono', 'Bitstream Vera Sans Mono', 'Courier New', Courier,
      monospace;
    font-size: var(--font-size-medium);
  }

  p,
  li {
    code {
      background: var(--background-active-light);
      border-radius: 4px;
      padding: 2px 5px;
      white-space: pre-wrap;
      color: var(--code);
    }
  }

  img {
    width: 200px;
  }
  .markdownTable {
    padding: 8px 0;
    overflow-x: scroll;

    table {
      width: 100%;
      box-sizing: border-box;
      border-collapse: separate;
      border-spacing: 0;
    }

    th,
    td {
      border: 0 solid var(--border-darker);
      padding: 8px 13px;
      vertical-align: top;
    }

    th {
      font-weight: 600;
      border-left-width: 1px;
      border-top-width: 1px;
      border-bottom-width: 1px;
    }

    td {
      border-radius: 0; /* reset the border radius for all td */
      border-bottom-width: 1px;
      border-left-width: 1px;
    }

    /* Top left rounded corner for the first header cell */
    th:first-child {
      border-top-left-radius: 6px;
    }

    /* Top right rounded corner for the last header cell */
    th:last-child {
      border-top-right-radius: 6px;
      border-right-width: 1px;
    }

    /* End of each row needs right border */
    td:last-child {
      border-right-width: 1px;
    }

    /* Bottom left rounded corner for the first cell in the last row */
    tr:last-child td:first-child {
      border-bottom-left-radius: 6px;
    }

    /* Bottom right rounded corner for the last cell in the last row */
    tr:last-child td:last-child {
      border-bottom-right-radius: 6px;
    }
  }
}
