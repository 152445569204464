@use 'src/styles/functions/index' as *;

// Styles shared between text inputs eg. UITextbox & UITextArea

.uiTextInputContainer {
  label {
    display: block;
    margin: 0 0 5px;
    font-size: var(--font-size-medium);
    font-weight: 500;
    .subLabel {
      font-size: var(--font-size-small);
      font-weight: normal;
      color: var(--text-light);
      margin-left: 4px;
    }
  }

  .uiTextInput {
    width: 100%;
    display: flex;
    align-items: center;
    vertical-align: bottom;
    justify-content: stretch;

    @include roundedBoxMedium;

    &.plain {
      border: none;
      background-color: inherit;
    }

    @include animate;

    &:hover {
      border-color: var(--border-dark);
    }

    // When input text has focus
    &:focus-within {
      border-color: var(--border-dark);
      background-color: var(--background);
    }

    &.withError {
      border-color: var(--alert);
    }

    &.readonly {
      background-color: var(--background-lightest);

      textarea,
      input {
        background-color: var(--background-lightest);
      }
    }

    textarea,
    input {
      font-size: var(--font-size-medium);
      display: block;
      flex: 1;
      @include roundedBoxMedium;
      padding: 8px 12px;
      color: var(--black);

      &:focus {
        outline: none;
      }
    }
  }

  .inputError {
    font-size: var(--font-size-medium);
    margin: 5px 0 0;
    color: var(--alert);
  }
}
