@use 'src/styles/functions/index' as *;

.resultsContainer {
  // Otherwise top header will overlap
  margin-top: 96px;

  .resultsListsWrapper {
    display: flex;
    flex-direction: column;
    &.isSearching {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

@include for-mobile-extended {
  .resultsContainer {
    margin-top: 125px;
  }
}
