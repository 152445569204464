@use 'src/styles/functions/index' as *;

.autoSuggestPopover {
  .dashAiSearch {
    display: flex;
    align-items: center;
    font-size: var(--font-size-medium);
    padding: 0 14px;
    min-height: 34px;
    cursor: pointer;

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.active {
      background-color: var(--background-lightest);
    }

    .label,
    svg {
      color: var(--text-light);
    }

    .query {
      max-width: 455px;
      @include ellipsis;
      margin: 0 5px;

      &.noQuery {
        margin: 0 4px 0 0;
      }
    }
  }
}
