table {
  border-collapse: collapse;
}

table.basic {
  width: 100%;
  font-size: var(--font-size-medium);
  thead {
    background-color: var(--background);
  }

  thead.stickyActive th {
    padding-bottom: 4px;
  }

  th {
    padding: 12px 2px;

    font-weight: 500;
    text-align: left;
    white-space: nowrap;

    svg,
    span {
      vertical-align: middle;
    }
    svg {
      margin-right: 4px;
    }

    .uiInfoTip {
      margin-top: -2px;
      margin-left: 6px;
    }
  }

  td {
    padding: 12px;
    border-bottom: 1px solid;
    border-bottom-color: var(--border);
  }

  tr:last-child td {
    border-bottom: 0;
  }
}
