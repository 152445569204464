@use 'src/styles/functions/index' as *;

.connectStep {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  .actionContainer {
    margin: 15px 0 20px;
  }

  .stepTitle {
    margin-left: -2px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .stepNum {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      @include circle;
      border: 1px solid;
      border-color: var(--border-dark);

      font-size: var(--font-size-medium);
    }

    h2 {
      margin: 0 0 0 15px;
      font-size: var(--font-size-larger);
    }
  }

  .stepField {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    font-size: var(--font-size-medium);
    margin-bottom: 16px;
  }

  .instructionSubGroup {
    padding: 16px;
    ul {
      padding-left: 8px;
      list-style-position: inside;
      margin: 0;
    }
    @include roundedBoxMedium;
    background-color: var(--background-lightest);
  }

  .screenFrame {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    @include roundedBoxSmall;
    background-color: var(--background-lightest);

    img {
      width: 100%;
    }
  }

  .stepFieldInput {
    width: 100%;
  }

  .note {
    color: var(--text-hint);
    font-size: var(--font-size-medium);
    text-align: right;
  }
}

.generalError {
  display: flex;
  margin: 20px 0;
  align-items: center;
  border-radius: 5px;
  border: 1px solid;
  color: var(--alert);
  border-color: var(--alert);
  padding: 11px 15px 10px;
  font-size: var(--font-size-larger);
  font-weight: 500;

  svg {
    margin-top: -2px;
  }

  > div {
    margin-left: 5px;
  }
}
