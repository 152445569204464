@use 'src/styles/functions/index' as *;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zIndexOverlay;
  pointer-events: auto;
  cursor: default;
}

.knowledgeSelect {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  min-width: 200px;
  border-radius: 8px;
  border: 1px solid var(--background-active);
  background: white;
  z-index: $zIndexKnowledgeSelectInput;
}

.popupFromTop {
  bottom: 40px;
}
.popupFromBottom {
  top: 40px;
}

.selectChevron {
  @include animate;
  margin-top: 0;
}
