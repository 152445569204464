body {
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  background-color: var(--background);
  height: 100%;
}

#dashworksRoot {
  height: 100%;
}

* {
  // Ensures that width/height includes any padding/borders
  // See: https://developer.mozilla.org/en-US/docs/Web/CSS/box-sizing
  box-sizing: border-box;
}

blockquote {
  margin: 0;
  padding: 0;
  border-left: 4px solid var(--text-hint) !important;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

hr {
  border: none;
  border-top: 1px solid var(--border);
}
