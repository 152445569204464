@use 'src/styles/functions/index' as *;

.uiSwitch {
  & .MuiSwitch-switchBase.Mui-checked {
    color: var(--primary);
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--primary);
  }
}
