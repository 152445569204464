@use 'src/styles/functions/index' as *;

.previewAttendeesContainer {
  .attendee {
    display: inline-flex;
    align-items: center;

    .attendeeStatus {
      width: 4px;
      height: 4px;
      display: inline-block;
      margin-right: 3px;
      @include circle;
      margin-bottom: -2px;

      &.accepted {
        background-color: var(--success);
      }

      &.needsAction {
        background-color: var(--caution);
      }
    }
  }
}
