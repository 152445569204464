@use 'src/styles/functions/index' as *;

.notice {
  border: 1px solid;
  border-color: var(--border);
  @include roundedBoxMedium;
  padding: 16px;
  background-color: var(--background);
  margin-top: 10px;
  margin-bottom: 25px;

  .title {
    font-weight: 400;
    margin-bottom: 20px;
    font-size: var(--font-size-medium);
    margin-top: 8px;
    b {
      font-weight: 600;
    }
  }

  .suggestion {
    color: var(--text-hint);
    font-size: var(--font-size-medium);
  }
}
