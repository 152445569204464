@use 'src/styles/functions/index' as *;

.qaPageViewContainer {
  display: flex;
  height: 100vh;

  .qaPageView {
    flex: 1;
    display: flex;
    flex-direction: column;

    .spacer {
      height: 20px;
      border-bottom: 1px solid var(--border);
    }
  }
}
