@use 'src/styles/functions/index' as *;

.qaMessageView {
  .linearProgress {
    background-color: transparent;
    .MuiLinearProgress-bar {
      background-color: var(--primary-active);
    }
  }

  .userMessage {
    a {
      color: inherit;
    }
  }

  .qaMessage {
    &:hover {
      .qaMessageRating {
        visibility: visible;
        opacity: 1;
      }
    }
    .qaMessageLoader {
      align-items: center;
    }

    display: flex;

    .qaAuthorIcon {
      display: flex;
      align-items: flex-start;
    }

    .qaMessageTools {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      gap: 8px;
      align-items: flex-end;
      &.top {
        justify-content: flex-start;
      }
      &.center {
        justify-content: center;
      }
    }
    .qaMessageRating {
      visibility: hidden;
      opacity: 0;
      margin-top: 4px;

      @include animate;
      .ratingRow {
        & .uiButton {
          flex-grow: 1;
          max-width: 50px;
          min-height: 16px;
          border: none;
          background-color: transparent;
          padding: 0;
        }
        .feedbackGiven {
          color: var(--text-light);
          width: auto;
        }
      }
    }

    .userInitials {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: var(--background-purple);
      color: var(--text-contrast);
      text-align: center;
      padding: 4px 0;
    }
    .qaMessageContentToolsRelated {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      .qaMessageContentTools {
        display: flex;
        justify-content: space-between;
        .qaMessageContent {
          max-width: 688px;

          @include for-mobile-only {
            max-width: calc(100vw - 80px);
          }
        }

        .loadingText {
          color: var(--text-hint);
        }
      }
    }
  }

  .timeDisplay {
    font-size: var(--font-size-small);
    color: var(--text-hint);
    min-width: 90px;
    text-align: right;
  }

  .appConnectionList {
    .expandableListItems {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .instantApp {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }

  .qaMessageContent {
    & > :last-child:after {
      content: '|';
      margin-left: 5px;
      width: 10px;
      height: 14px;
      background-color: var(--text);
      animation: blinker 1s linear infinite;
      display: none;
    }
    &.streaming > :last-child:after {
      display: inline;
    }
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  &.userMessage.focused {
    animation: animate-focus 2s;
  }

  @keyframes animate-focus {
    0% {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    50% {
      box-shadow: 0 0 10px #dd973a;
    }
    100% {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
  }
}

@include for-mobile-extended {
  .qaMessageTools {
    .timeDisplay {
      display: none;
    }
  }
}

.paper {
  pointer-events: auto;

  .referencePreview {
    border-radius: 8px;
    width: 336px;
    .header {
      padding: 8px 16px;

      display: flex;
      gap: 16px;
      justify-content: space-between;
      border-bottom: 1px solid var(--border);

      .title {
        font-weight: 600;
        max-width: 300px;
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .extraFields {
      padding-bottom: 4px;
      .fieldContainer {
        padding: 4px 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: var(--font-size-medium);
        .fieldName {
          color: var(--text-light);
          flex: 1;
        }
      }
    }
  }
}
