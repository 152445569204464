.errorDisplay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10%;
  height: 100%;

  pre {
    font-family: Consolas, monospace;
    font-size: var(--font-size-small);
  }

  .icon {
    margin-bottom: 20px;
    color: var(--text-lighter);
  }
}
