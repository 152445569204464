@use 'src/styles/functions/index' as *;

.MuiDialog-root {
  .MuiDialogContent-root {
    padding: 0;

    &:first-child {
      padding-top: 0;
    }
  }

  .MuiPaper-rounded {
    @include roundedBoxMedium;
  }

  .MuiPaper-root {
    cursor: default;

    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      > .header {
        width: 100%;
        padding: 16px 24px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: 1px solid;
        border-bottom-color: var(--border);
        background-color: var(--background);
        z-index: $zIndexDialogHeader;
        display: flex;

        .closeDialog {
          position: absolute;
          top: 16px;
          right: 12px;
        }

        .title {
          display: flex;
          align-items: center;
          margin-right: 8px;
          font-weight: 500;

          img,
          svg {
            width: 20px;
            height: 20px;
          }

          h1 {
            margin: 0 0 0 8px;
            font-size: var(--font-size-medium);
            font-weight: 500;
          }
        }
      }
    }

    .content {
      flex: 1;
      overflow-y: auto;
      padding: 20px 28px 24px;
    }

    .footer {
      display: flex;
      border-top: 1px solid;
      border-top-color: var(--border);
      justify-content: space-evenly;
      padding: 12px 24px;
    }

    .modalActions {
      &.reverse {
        flex-direction: row-reverse;
      }
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
