/* stylelint-disable scss/no-dollar-variables */
// Use this to keep track of overlapping elements
$zIndexAdminHeader: 50;
$zIndexAutosuggest: 41;
$zIndexSocialUserIcons: 20;
$zIndexDialogHeader: 10;
$zIndexFeedback: 40;
$zIndexHelpButton: 55;
$zIndexInputTextBox: 150;
$zIndexInputTextBoxLeftControls: 1500;
$zIndexPageSearchTopHeader: 45;
$zIndexResultPreview: 42;
$zIndexSidebar: 140;
$zIndexStickyHeader: 1;
$zIndexToasts: 2000;
$zIndexTopHeaderHeader: 1;
$zIndexHomepageHighlightedSearch: 1602;
$zIndexDeveloperIndicator: 9999;
$zIndexOverlay: 99997;
$zIndexKnowledgeSelectInput: 99998;
$zIndexNotificationSidebarDesktop: 139;
$zIndexNotificationSidebarMobile: 141;
