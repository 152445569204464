@use './window-size.scss' as *;

@mixin roundedBoxLarge {
  border-radius: 20px;
}

@mixin roundedBoxMedium {
  border-radius: 8px;
}

@mixin roundedBoxSmall {
  border-radius: 4px;
}

@mixin circle {
  border-radius: 100%;
}

@mixin backgroundImage {
  background-color: rgba(0, 0, 0, 0.2); /* Tint color */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  @include for-mobile-extended {
    background-size: auto;
  }
}
