@use 'src/styles/functions/index' as *;

.sticky {
  position: sticky;

  &.stickyActive {
    box-shadow: 0 5px 5px -5px var(--shadow-dark-color);
    z-index: $zIndexStickyHeader;
  }
}
