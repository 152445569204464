@use 'src/styles/functions/index' as *;

.metadataMatches {
  margin: 8px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px 30px;

  .matchItem {
    color: var(--text-light);
    font-size: var(--font-size-medium);
    @include clampLines(1);
  }
}
