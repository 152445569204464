@use 'src/styles/functions/index' as *;

.homepage {
  &.withBackground {
    @include backgroundImage;
  }
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  height: 100%;
  user-select: none;
  cursor: default;

  @include animate('background-color');

  @include contrast {
    color: var(--text-contrast);
  }

  // We use this instead of `justify-content: center` on the parent container due to this flexbox scrolling issue:
  // https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container
  .homepageContainer {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: center;
    margin: auto 0;
  }

  @include for-mobile-extended {
    .homepageContainer {
      padding-top: 0;
      height: 90%;
      margin-top: 20%;
      row-gap: 4vh;
    }
  }

  .greeting {
    width: var(--homepage-main-column-width);
    justify-content: center;
  }

  h1 {
    font-family: 'Victor Serif', 'Times', serif;
    font-size: var(--font-size-hero-heading);
    line-height: 48px;
    text-align: center;
    margin-bottom: 40px;
  }

  .suggestedDocsContainer {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    width: var(--homepage-main-column-width);
    max-height: 450px;
    max-width: var(--homepage-main-column-width);
  }
  @include for-mobile-extended {
    .suggestedDocsContainer {
      width: 90%;
    }
    .greeting {
      width: 90%;
    }
  }

  .configButtons {
    position: fixed;
    bottom: 20px;
    right: 72px;
    display: flex;
    justify-content: flex-end;
    gap: 24px;

    .configBtn {
      padding: 4px;
      border: 0.1px solid currentColor;
      border-radius: 48%;
      background-color: var(--background);
      color: var(--text-light);

      &.active,
      &:hover {
        background-color: var(--background-lightest);
        color: var(--text);
      }
    }
  }
  @include for-mobile-only {
    .configButtons {
      display: none;
    }
  }
}
