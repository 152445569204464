@use 'src/styles/functions/index' as *;

.pageHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;

  &.topMargin {
    margin-top: 20px;
  }

  &.withBorder {
    margin-bottom: 30px;
    border-bottom: 1px solid;
    border-bottom-color: var(--border);
  }

  .headerLeft {
    padding-right: 50px;
  }

  .title {
    font-weight: 500;
    margin: 0 0 8px;
  }

  .subTitle {
    color: var(--text-light);
    font-weight: 400;
    font-size: var(--font-size-medium);
  }
}
