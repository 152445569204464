@use 'src/styles/functions/index' as *;

.metaPairs {
  font-size: var(--font-size-medium);
  overflow: hidden;

  td {
    padding-top: 2px;
    padding-bottom: 2px;
    vertical-align: top;

    > span {
      margin-right: 5px;
    }
  }

  .pairName {
    padding-right: 16px;
    color: var(--text-light);
  }

  .pairValue {
    word-break: break-word;
    .heavier {
      font-weight: 500;
    }
  }
}
