@use 'src/styles/functions/index' as *;

.dataPlaceholder {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-top: 80px;

  h3 {
    margin-bottom: 4px;
    font-size: var(--font-size-larger);
    font-weight: 500;
  }

  .icon {
    width: 96px;
    height: 96px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include circle;
    background-color: var(--background-neutral-light);
    margin-bottom: 4px;
    color: var(--primary);
  }

  p {
    color: var(--text-light);
    margin-top: 0;
    font-size: var(--font-size-medium);
    margin-bottom: 24px;
  }
}
