@use 'src/styles/functions/index' as *;

.fileTypeIcon {
  margin-top: -2px;
  &.doc {
    color: var(--color-doc);
  }
  &.slide {
    color: var(--color-slide);
  }
  &.sheet {
    color: var(--color-sheet);
  }
  &.pdf {
    color: var(--color-pdf);
    path {
      stroke: none;
    }
  }
}
