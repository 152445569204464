@mixin fontFaces($pathToAssets) {
  @font-face {
    font-family: 'GT Walsheim';
    src: url('#{$pathToAssets}/fonts/Web/gt-walsheim/GT-Walsheim-Regular.otf')
      format('opentype');
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'GT Walsheim';
    src: url('#{$pathToAssets}/fonts/Web/gt-walsheim/GT-Walsheim-Medium.otf')
      format('opentype');
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: 'GT Walsheim';
    src: url('#{$pathToAssets}/fonts/Web/gt-walsheim/GT-Walsheim-Bold.otf')
      format('opentype');
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: 'GT Walsheim';
    src: url('#{$pathToAssets}/fonts/Web/gt-walsheim/GT-Walsheim-Black.otf')
      format('opentype');
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: 'Victor Serif';
    src: url('#{$pathToAssets}/fonts/Web/victor-serif/VictorSerif-Regular.otf')
      format('opentype');
    font-style: normal;
    font-weight: 700;
  }
}
