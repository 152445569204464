/* Hide scrollbar for Chrome, Safari and Opera */
.references::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.references {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
}
