.messageResults {
  margin: 8px 0;
  > .title {
    align-items: center;
    display: flex;
    gap: 4px;
    margin-bottom: 8px;
    margin-left: 12px;
  }

  .messageResultsList {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .showMoreButton {
    margin-left: 8px;
  }
}
