@use 'src/styles/functions/index' as *;
@use '../common/TextInput';

.uiTextboxContainer {
  .fixedText {
    font-size: var(--font-size-medium);
    padding: 11px 17px;
    right: 1px;
    top: 1px;
    background-color: var(--background-active);
    color: var(--text-light);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &.large {
    .iconContainer {
      margin-left: 15px;
    }

    .uiTextInput input {
      font-size: var(--font-size-larger);
      padding: 11px 17px 10px;
    }

    .inputError {
      margin: 8px 0 0;
    }

    .copyValue {
      width: 20px;
    }
  }

  .uiTextInput {
    // When input text has focus
    &:focus-within {
      .controls .hint {
        display: inline-block;
      }
    }

    .leftControls {
      margin-left: 5px;

      // Ensures we stay above the div to hide box shadow
      z-index: $zIndexInputTextBoxLeftControls;
      position: relative;
    }

    .iconContainer {
      margin: -2px 0 0 13px;
      cursor: pointer;
      opacity: 0.7;
      @include animate;

      &:hover {
        opacity: 1;
      }

      .MuiSvgIcon-root {
        color: var(--text-hint);
      }
    }

    input {
      &.withIcon {
        padding-left: 7px;
      }
      &.showWhiteField {
        background-color: var(--background);
      }
    }
  }

  .inputError {
    font-size: var(--font-size-medium);
    margin: 5px 0 0;
    color: var(--alert);
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .hint {
      // Only show with focus
      display: none;
      pointer-events: none;
    }

    svg {
      width: 18px;
      margin-right: 10px;
    }
  }
}
