@use 'src/styles/functions/index' as *;

.envIndicator {
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  flex-direction: column;
  position: fixed;
  z-index: $zIndexDeveloperIndicator;
  cursor: pointer;
  right: -100px;
  top: -100px;
  height: 200px;
  width: 200px;
  transform: rotate(45deg);
  padding: 4px;
  max-width: 400px;

  &.open {
    right: 0;
    top: 0;
    height: auto;
    width: auto;
    transform: none;
    cursor: default;
  }

  &.envProd {
    @keyframes prod-pulse {
      /* stylelint-disable scale-unlimited/declaration-strict-value */
      0% {
        background-color: red;
      }

      50% {
        background-color: #b22;
      }

      100% {
        background-color: red;
      }
      /* stylelint-enable */
    }
    animation: prod-pulse 2s infinite;
  }

  > .menu {
    display: flex;
    gap: 4px;
  }
}
