@use 'src/styles/functions/index' as *;

.accessDiscovery {
  --blureffect: blur(3.5px) brightness(80%) saturate(200%) contrast(150%);
  .appTooltip,
  .title,
  .breadcrumbs,
  .contentSnippet {
    filter: var(--blureffect);
  }

  position: relative;
  &:hover {
    cursor: default;
    background-color: var(--background-lightest);
  }
}

.accessDiscovery .lock {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: none;
}

.lock {
  display: none;
}
.accessDiscovery:hover .lock {
  width: 36px;
  height: 36px;
}
