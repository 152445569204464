.loadingSplash {
  color: var(--text-light);
  width: 100%;
  height: 100%;
  display: flex;
  gap: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
