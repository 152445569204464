@use 'src/styles/functions/index' as *;

.personAvatar {
  @include circle;
  margin-top: 2px;
}

.personAvatarWrapper {
  position: relative;

  .svgIcon.app {
    position: absolute;
    top: 50%;
    left: 50%;
    background: var(--background);
    @include circle;
  }
}
