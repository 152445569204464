@use 'src/styles/functions/index' as *;

.inPageAppFilter {
  width: 80%;
  @include roundedBoxLarge;
  opacity: 1;
  transition: opacity var(--duration-fast);
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  > .header {
    color: var(--text-hint);
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    margin-left: 6px;
    > .reset {
      margin-left: auto;
    }
  }
  > .itemListWrapper {
    display: flex;
    flex-direction: column;
    &:hover > .itemList {
      max-height: 100vh;
      &.abridged:before {
        opacity: 0;
      }
    }
    > .itemList {
      --relevant-count: 1;
      display: flex;
      flex-direction: column;
      gap: 2px;
      max-height: calc(42px * (min(var(--relevant-count, 4)) + 2));
      overflow: hidden;
      transition: max-height var(--duration-medium) ease-in-out;
      position: relative;

      &.abridged {
        &:before {
          transition: opacity var(--duration-medium) ease-in-out;
          opacity: 1;
          background-image: linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 255)
          );
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          height: 200px;
          pointer-events: none;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          color: var(--text-lighter);
          font-size: var(--font-size-largerer);
          content: '↓';
        }
      }
    }
    .connectMore {
      margin-top: 8px;
    }
  }
}
@include for-mobile-extended {
  .inPageAppFilter {
    display: none;
  }
}
