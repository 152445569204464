@use 'src/styles/functions/index' as *;

/* stylelint-disable-next-line scss/no-dollar-variables */
$padding: 14px;

.gridItem {
  cursor: pointer;
  display: block;
  position: relative;
  overflow: hidden;
  @include roundedBoxMedium;
  border: 1px solid;
  border-color: var(--border);

  .details {
    padding: $padding;
    h4 {
      margin-top: 4px;
      font-size: var(--font-size-larger);
      font-weight: 500;
    }
  }
  img {
    margin: 0 auto;
    display: block;
    width: auto;
    max-height: 50%;
  }

  .actions {
    display: none;
    position: absolute;
    bottom: $padding;
    right: $padding;
  }

  .metaPairs {
    position: absolute;
    bottom: $padding;
    left: $padding;
  }

  &:hover,
  &:focus,
  &:focus-within {
    background-color: var(--background-lightest);

    .actions {
      display: flex;
    }
  }
}
